<template>
  <div>
    <!-- Go through each influencer and show there data -->
    <div v-for="item in influencers" :key="`${item.platform}-${item.username}`">
      <v-card class="rounded pt-6 mb-8">
        <!-- Show a preview item -->
        <div class="px-8 d-flex align-center justify-space-between">
          <!-- Show the influencer preview here -->
          <div class="d-flex align-center">
            <!-- Show the platform  -->
            <v-img :src="`/img/socials/${item.platform}.svg`" max-width="32" height="32" class="mr-3"></v-img>

            <!-- Show the preview chip -->
            <profile-chip :platform="item.platform" :data="item.content.influencer" />
          </div>

          <!-- Show the average stats here -->
          <div class="d-flex">
            <!-- For posts -->
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-chip color="secondary" class="ml-2" text-color="white" v-bind="attrs" v-on="on">
                  <v-avatar left>
                    <v-icon small>collections</v-icon>
                  </v-avatar>

                  {{ nFormatter(item.content.posts.length) }} {{ item.content.posts.length > 1 ? "posts" : "post" }}
                </v-chip>
              </template>

              <span> {{ item.content.posts.length }} posts </span>
            </v-tooltip>

            <!-- For likes -->
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-chip color="secondary" class="ml-2" text-color="white" v-bind="attrs" v-on="on">
                  <v-avatar left>
                    <v-icon small>thumb_up</v-icon>
                  </v-avatar>

                  {{ nFormatter(item.stats.likes) }}
                </v-chip>
              </template>

              <span> {{ item.stats.likes }} Likes </span>
            </v-tooltip>

            <!-- For views -->
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-chip color="secondary" class="ml-2" text-color="white" v-bind="attrs" v-on="on">
                  <v-avatar left>
                    <v-icon small>play_circle</v-icon>
                  </v-avatar>

                  {{ nFormatter(item.stats.views) }}
                </v-chip>
              </template>

              <span> {{ item.stats.views }} Views </span>
            </v-tooltip>

            <!-- For comments -->
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-chip color="secondary" class="ml-2" text-color="white" v-bind="attrs" v-on="on">
                  <v-avatar left>
                    <v-icon small>chat_bubble</v-icon>
                  </v-avatar>

                  {{ nFormatter(item.stats.comments) }}
                </v-chip>
              </template>

              <span> {{ item.stats.comments }} Comments </span>
            </v-tooltip>

            <!-- For Engagement Rate -->
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-chip color="secondary" class="ml-2" text-color="white" v-bind="attrs" v-on="on">
                  <v-avatar left>
                    <v-icon small>bar_chart</v-icon>
                  </v-avatar>

                  {{ item.stats.engagementRate.toFixed(2) }}%
                </v-chip>
              </template>

              <span> {{ item.stats.engagementRate.toFixed(2) }}% Average Engagement Rate </span>
            </v-tooltip>
          </div>
        </div>

        <!-- Grid: Table comparison -->
        <v-row class="mt-4">
          <!-- Likes -->
          <v-col cols="12" md="6" lg="4" class="text-center">
            <template v-if="!shouldHideAverageValues">
              <apex-chart
                type="bar"
                :height="180"
                :options="averageChartOptions(getSeries(item, 'likes'))"
                :series="getSeries(item, 'likes')"
              />
              <!-- Show the likes value -->
              <!-- <div class="text-subtitle-2">
                Average Likes in Campaign: {{ nFormatter(item.averageComparison.likes) }}
              </div> -->

              <!-- Show the likes value -->
              <!-- <div class="text-body-2">
                Average Influencer Likes: {{ nFormatter(item.average.likes) }}
              </div> -->
            </template>

            <template v-if="item.content.posts.length > 1">
              <div class="text-overline mt-3">{{ shouldHideAverageValues ? "Likes % share" : "%" }} of the campaign</div>

              <!-- Show the pie chart -->
              <div>
                <apex-chart
                  type="donut"
                  height="180"
                  width="100%"
                  :options="getOptions(item.comparison.likes)"
                  :series="[item.comparison.likes, 100 - item.comparison.likes]"
                />
              </div>
            </template>
          </v-col>

          <!-- Views -->
          <v-col cols="12" md="6" lg="4" class="text-center">
            <template v-if="!shouldHideAverageValues">
              <apex-chart
                type="bar"
                :height="180"
                :options="averageChartOptions(getSeries(item, 'views'))"
                :series="getSeries(item, 'views')"
              />
              <!-- Show the views value -->
              <!-- <div class="text-subtitle-2">
                Average Views in Campaign: {{ nFormatter(item.averageComparison.views) }}
              </div> -->

              <!-- Show the views value -->
              <!-- <div class="text-body-2">
                Average Influencer Views: {{ nFormatter(item.average.views) }}
              </div> -->
            </template>

            <template v-if="item.content.posts.length > 1">
              <div class="text-overline mt-3">{{ shouldHideAverageValues ? "Views % share" : "%" }} of the campaign</div>

              <!-- Show the pie chart -->
              <div>
                <apex-chart
                  type="donut"
                  height="180"
                  width="100%"
                  :options="getOptions(item.comparison.views)"
                  :series="[item.comparison.views, 100 - item.comparison.views]"
                />
              </div>
            </template>
          </v-col>

          <!-- Comments -->
          <v-col cols="12" md="6" lg="4" class="text-center">
            <template v-if="!shouldHideAverageValues">
              <apex-chart
                type="bar"
                :height="180"
                :options="averageChartOptions(getSeries(item, 'comments'))"
                :series="getSeries(item, 'comments')"
              />

              <!-- Show the comments value -->
              <!-- <div class="text-subtitle-2">
                Average Comments in Campaign: {{ nFormatter(item.averageComparison.comments) }}
              </div> -->

              <!-- Show the comments value -->
              <!-- <div class="text-body-2">
                Average Influencer Comments: {{ nFormatter(item.average.comments) }}
              </div> -->
            </template>

            <template v-if="item.content.posts.length > 1">
              <div class="text-overline mt-3">{{ shouldHideAverageValues ? "Comments % share" : "%" }} of the campaign</div>

              <!-- Show the pie chart -->
              <div>
                <apex-chart
                  type="donut"
                  height="180"
                  width="100%"
                  :options="getOptions(item.comparison.comments)"
                  :series="[item.comparison.comments, 100 - item.comparison.comments]"
                />
              </div>
            </template>
          </v-col>
        </v-row>

        <!-- Grid: Audience insight information -->
        <v-row class="mt-4">
          <!-- Show gender on left -->
          <v-col cols="12" md="6" lg="3">
            <div class="text-overline mb-2 pl-8">Gender Distribution</div>

            <gender-split :audience-data="item.computed_average_value" />
          </v-col>

          <!-- Show age on right -->
          <v-col cols="12" md="6" lg="3">
            <div class="text-overline mb-2 pl-4">Age Distribution</div>

            <age-and-gender :audience-data="item.computed_average_value" />
          </v-col>

          <!-- Show country locations on left -->
          <v-col cols="12" md="6" lg="3" v-if="item.computed_average_value.geo.countries.length">
            <div class="text-overline mb-2 pl-4">Top Countries</div>

            <location-chart :data="item.computed_average_value.geo.countries" />
          </v-col>

          <!-- Show city locations on left -->
          <v-col cols="12" md="6" lg="3" v-if="item.computed_average_value.geo.cities.length">
            <div class="text-overline mb-2 pl-4">Top Cities</div>

            <location-chart :data="item.computed_average_value.geo.cities" />
          </v-col>
        </v-row>

        <!-- Show a divider -->
        <v-divider class="my-4" />

        <!-- Show posts one by one -->
        <v-container>
          <v-row v-for="post in item.content.posts" :key="'post-' + post.id" class="pb-4 align-center">
            <!-- Show the thumbnail in left -->
            <v-col cols="12" md="6" lg="4" class="pl-4">
              <post :data="post" />
            </v-col>

            <!-- Show the values in right -->
            <v-col cols="12" md="6" lg="8">
              <!-- Show a grid inside it -->
              <v-row>
                <!-- For Likes -->
                <v-col cols="12" lg="6">
                  <div class="text-body-2 text-center font-weight-bold">{{ nFormatter(post.likes) }} Likes</div>

                  <div class="text-overline text-center">% of the campaign</div>

                  <!-- Show the pie chart -->
                  <div>
                    <apex-chart
                      type="donut"
                      height="180"
                      width="100%"
                      :options="getOptions(post.comparison.likes)"
                      :series="[post.comparison.likes, 100 - post.comparison.likes]"
                    />
                  </div>
                </v-col>

                <!-- For Views -->
                <v-col cols="12" lg="6">
                  <div class="text-body-2 text-center font-weight-bold">{{ nFormatter(post.views) }} Views</div>

                  <div class="text-overline text-center">% of the campaign</div>

                  <!-- Show the pie chart -->
                  <div>
                    <apex-chart
                      type="donut"
                      height="180"
                      width="100%"
                      :options="getOptions(post.comparison.views)"
                      :series="[post.comparison.views, 100 - post.comparison.views]"
                    />
                  </div>
                </v-col>

                <!-- For Comments -->
                <v-col cols="12" lg="6">
                  <div class="text-body-2 text-center font-weight-bold">{{ nFormatter(post.comments) }} Comments</div>

                  <div class="text-overline text-center">% of the campaign</div>

                  <!-- Show the pie chart -->
                  <div>
                    <apex-chart
                      type="donut"
                      height="180"
                      width="100%"
                      :options="getOptions(post.comparison.comments)"
                      :series="[post.comparison.comments, 100 - post.comparison.comments]"
                    />
                  </div>
                </v-col>

                <!-- For Engagement Rate -->
                <!-- <v-col cols="12" lg="6">
                  <div class="text-body-2 text-center font-weight-bold">
                    {{ post.engagementRate }}% Engagement Rate
                  </div>

                  <div class="text-overline text-center">
                    % compared to campaign
                  </div> -->

                <!-- Show the pie chart -->
                <!-- <div>
                    <apex-chart
                      type="donut"
                      height="180"
                      width="100%"
                      :options="getOptions(post.comparison.engagementRate)"
                      :series="[post.comparison.engagementRate, 100 - post.comparison.engagementRate]"
                    />
                  </div>
                </v-col> -->
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </div>

    <!-- Trigger point for dynamic scroll -->
    <div id="intersect-detector" ref="intersectDetector" v-intersect="handleIntersect"></div>
  </div>
</template>

<script>
// Import node packages
import ApexChart from "vue-apexcharts"
import merge from "lodash/merge"

// Import child components
const Post = () => import(/* webpackChunkName: "crm-post" */ "@/components/crm/Post")
const DayWise = () => import(/* webpackChunkName: "crm-day-wise" */ "@/components/crm/DayWise")
const ProfileChip = () => import(/* webpackChunkName: "profile-chip" */ "@/blocks/common/ProfileChip")
const GenderSplit = () => import(/* webpackChunkName: "charts-gender-split" */ "@/blocks/user-profile/charts/GenderSplit.vue")
const AgeAndGender = () => import(/* webpackChunkName: "charts-age-gender" */ "@/blocks/user-profile/charts/AgeAndGender.vue")
const LocationChart = () => import(/* webpackChunkName: "charts-location" */ "@/blocks/user-profile/charts/Location")

// Import default configuration option for chart
import apexChartBaseOptions from "@/json/apexChartBaseOptions.js"

// Export the SFC
export default {
  // Name of the component
  name: "CRMReport",

  // Register children components
  components: {
    ApexChart,

    Post,
    DayWise,
    ProfileChip,
    GenderSplit,
    AgeAndGender,
    LocationChart
  },

  // Accept incoming data from parent
  props: {
    overview: {
      type: Object,
      required: true
    },

    shouldHideAverageValues: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  // Define local data variables
  data: () => ({
    // Whether or not a request is being made currently
    isMakingRequest: false,

    // The current page number
    currentPage: 1,
    // The per page amount
    perPage: 2,

    // Store the API response
    response: {
      data: [],
      total: 10
    }
  }),

  // Define readonly data variables
  computed: {
    /**
     * Get the selected platform
     *
     * @returns {String}
     */
    selectedPlatform() {
      return this.$store.getters["campaignTracking/selectedPlatformById"](this.overview.model.id)
    },

    /**
     * Get the selected influencer
     *
     * @returns {Object}
     */
    selectedInfluencer() {
      return this.$store.getters["campaignTracking/selectedInfluencerById"](this.overview.model.id)
    },

    /**
     * Get the influencers
     *
     * @returns {Array}
     */
    influencers() {
      return this.response.data.map((item) => {
        // Get the total likes, views and comments
        const influencerLikes = item.content.posts.reduce((acc, post) => acc + post.likes, 0)
        const influencerViews = item.content.posts.reduce((acc, post) => acc + post.views, 0)
        const influencerComments = item.content.posts.reduce((acc, post) => acc + post.comments, 0)

        return merge(item, {
          stats: {
            likes: influencerLikes,
            views: influencerViews,
            comments: influencerComments,
            engagementRate:
              (100 *
                (item.content.posts.reduce(
                  (acc, post) => acc + (post.likes || 0) + (post.comments || 0) + (post.saves || 0) + (post.shares || 0),
                  0
                ) /
                  (item.content.influencer.followers || 1))) /
              item.content.posts.length
          },

          comparison: {
            likes: parseFloat(((100 * influencerLikes) / (this.overview.categories.all[0].likes || 1)).toFixed(2)),
            views: parseFloat(((100 * influencerViews) / (this.overview.categories.all[0].views || 1)).toFixed(2)),
            comments: parseFloat(((100 * influencerComments) / (this.overview.categories.all[0].comments || 1)).toFixed(2))
          },

          averageComparison: {
            likes: Math.floor(influencerLikes / item.content.posts.length),
            views: Math.floor(influencerViews / item.content.posts.length),
            comments: Math.floor(influencerComments / item.content.posts.length)
          },

          content: {
            posts: item.content.posts.map((post) => {
              return merge(post, {
                comparison: {
                  likes: parseFloat(((100 * post.likes) / (this.overview.categories.all[0].likes || 1)).toFixed(2)),
                  views: parseFloat(((100 * post.views) / (this.overview.categories.all[0].views || 1)).toFixed(2)),
                  comments: parseFloat(((100 * post.comments) / (this.overview.categories.all[0].comments || 1)).toFixed(2))
                }
              })
            })
          }
        })
      })
    }
  },

  // Set watchers for local data
  watch: {
    selectedPlatform: {
      handler() {
        // Ask to fetch the data from the server again
        this.fetchInfluencers(true)
      }
    },

    selectedInfluencer: {
      handler() {
        // Ask to fetch the data from the server again
        this.fetchInfluencers(true)
      }
    }
  },

  // Define local method functions
  methods: {
    /**
     *
     * @param {Object} influencer
     * @param {String} type
     * @returns {Array}
     */
    getSeries(influencer, type) {
      return [
        {
          color: "#EB4D4B",
          name: `Average ${type} in campaign`,
          data: [
            {
              x: type,
              y: influencer.averageComparison[type]
            }
          ]
        },
        {
          color: "#2196F3",
          name: `Average influencer ${type}`,
          data: [
            {
              x: type,
              y: influencer.average[type]
            }
          ]
        }
      ]
    },

    /**
     *
     *
     * @param {Object} influencer
     */
    getOptions(value) {
      return merge(apexChartBaseOptions(), {
        labels: [],
        tooltip: {
          enabled: false
        },
        chart: {
          toolbar: {
            show: false
          }
        },
        colors: ["#f05d56", "#eeeeee"],
        stroke: { colors: ["#fff"] },
        legend: {
          show: false
        },
        dataLabels: {
          enabled: false
        },
        annotations: {
          texts: [
            {
              x: "51%",
              y: "52%",
              text: value + "%",
              textAnchor: "middle",
              fontSize: "16px",
              fontWeight: "bold",
              // foreColor: '#000000',
              paddingTop: 8
            }
          ]
        },
        states: {
          normal: {
            filter: {
              type: "none",
              value: 0
            }
          },
          hover: {
            filter: {
              type: "none",
              value: 0
            }
          },
          active: {
            filter: {
              type: "none",
              value: 0
            }
          }
        },
        plotOptions: {
          pie: {
            startAngle: 0,
            endAngle: 360,
            expandOnClick: false,
            donut: {
              labels: {
                show: false
              },
              value: {
                show: false
              }
            }
          }
        }
      })
    },

    /**
     * Get the chart options for horizontal bars
     *
     * @param {Array} series
     * @returns {Object}
     */
    averageChartOptions(series) {
      return merge(apexChartBaseOptions(), {
        chart: {
          toolbar: {
            show: false
          }
        },
        stroke: {
          width: 5
        },
        legend: {
          labels: {
            colors: [host.theme.color.text],
            useSeriesColors: false
          },
          horizontalAlign: "center",
          formatter: (seriesName, opts) => {
            return `${seriesName}: ${nFormatter(series[opts.seriesIndex].data[0].y)}`
          }
        },
        xaxis: {
          labels: {
            formatter: (val) => nFormatter(val)
          }
        },
        yaxis: {
          type: "numeric",
          labels: {
            formatter: (val) => nFormatter(val)
          },
          labels: {
            show: false
          }
        },
        tooltip: {
          fillSeriesColor: false,
          y: {
            formatter: (val) => val,
            title: {
              formatter: (seriesName) => seriesName.split(":")[0] + ":"
            }
          }
        },
        plotOptions: {
          bar: {
            barHeight: "42%",
            horizontal: true,
            columnWidth: "42%",
            borderRadius: 4
          }
        }
      })
    },

    /**
     * Whether or not the user has scrolled past the intersection limit
     *
     * @param {Object} entries
     * @param {Object} observer
     * @param {Boolean} isIntersecting
     */
    handleIntersect(entries, observer, isIntersecting) {
      // Stop execution if user didn't scroll down
      if (!isIntersecting) return false

      // If a request is being made, don't continue
      if (this.isMakingRequest) return false

      // If there's no data possible, don't continue
      if (this.response.data.length >= this.response.total) return false

      // Otherwise, call the search function
      this.currentPage++
      this.fetchInfluencers()
    },

    /**
     * Fetch the influencers
     *
     * @param {Boolean} reset
     * @returns {Promise}
     */
    async fetchInfluencers(reset = false) {
      // If we should reset the values
      if (reset) {
        // Reset the page number
        this.currentPage = 1
        this.perPage = 2

        // Reset the response data
        this.response.data = []
        this.response.total = 10
      }

      // Show a loader
      const loaderId = Symbol("CampaignTracking/fetchAnalyticsReport")
      this.$store.dispatch("loaders/add", loaderId)
      this.isMakingRequest = true

      // Get the influencer object from API
      try {
        // Define the query params
        const queryParams = new window.URLSearchParams(
          Object.entries({
            page: this.currentPage,
            per_page: this.perPage,

            platform: this.selectedPlatform,
            influencer: this.selectedInfluencer ? this.selectedInfluencer.id : null
          }).filter(([key, value]) => Boolean(value))
        )

        // Use helper function
        const response = await axios(`/api/campaign-tracking/${this.overview.model.id}/analytics/report?${queryParams}`)

        // Update the data
        this.response.data = [...this.response.data, ...response.data.data]
        this.response.total = response.data.total
      } catch (error) {
        // Catch any error
        // Show a toast
        this.$store.dispatch("toasts/add", { text: "Failed to fetch report!" })

        // Log it
        logger({ type: "CampaignTracking/fetchAnalyticsReport Error", error })
      } finally {
        // Nonetheless
        // Hide the loader
        this.$store.dispatch("loaders/remove", loaderId)
        this.isMakingRequest = false
      }
    }
  },

  /**
   * As soon as the component data is ready
   *
   * @returns {void}
   */
  created() {
    // If there is no data
    if (this.response.data.length === 0) {
      // Fetch the influencers
      this.fetchInfluencers()
    }
  }
}
</script>
